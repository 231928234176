<template>
  <main class="d-flex flex-column fill-height white">
    <table-page-main-header title="Countries" />
    <section class="flex-grow-1">
      <data-table
        :headers="$options.tableHeadersConfig"
        :items="countries"
        :options.sync="options"
        :loading="isLoading"
        :items-per-page="100"
        :disable-pagination="isLoading"
        :server-items-length="serverItemsLength"
      >
        <template v-slot:item.name="{ item }">
          <router-link
            class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
            :to="{ name: 'CountryDetails', params: { id: item.id } }"
          >
            {{ item.name }}
          </router-link>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import TablePageMainHeader from '@/components/common/TablePageMainHeader.vue';

import { transformDataTableOptionsForRequest, getLabelFromBoolean, taxFormatter } from '@/utils';

import { getCountriesList } from '@/api/countries.api';

import { SORTING_DIRECTIONS } from '@/constants/common';

const FIELD_NAMES = {
  ID: 'id',
  NAME: 'name',
  LOGISTIC_CENTER: 'logisticCenterName',
  VAT: 'vatRate',
  CONSULTATION_FEE: 'basicConsultationFee',
  DELIVERY_FEE: 'deliveryContributionPercentage',
  LEVEL_TWO_ACTIVATED: 'levelTwoActivated',
  DOCTOR_ACCOUNTS: 'doctorAccounts',
  DEMO_ACCOUNTS: 'demoAccounts'
};

const FIELD_NAMES_FOR_PARAMS = {
  [FIELD_NAMES.NAME]: 'name',
  [FIELD_NAMES.LEVEL_TWO_ACTIVATED]: 'levelTwoActivated',
  [FIELD_NAMES.DOCTOR_ACCOUNTS]: 'doctorAccounts',
  [FIELD_NAMES.DEMO_ACCOUNTS]: 'demoAccounts'
};

const DEFAULT_SORT_BY_FIELD = FIELD_NAMES.NAME;

const COUNTRIES_TABLE_HEADER_CONFIG = [
  { text: 'Name', value: FIELD_NAMES.NAME, sortable: true },
  { text: 'Logistic center', value: FIELD_NAMES.LOGISTIC_CENTER, width: '200px', sortable: false },
  { text: 'VAT', value: FIELD_NAMES.VAT, width: '120px', sortable: false },
  {
    text: 'Basic consultation fee',
    value: FIELD_NAMES.CONSULTATION_FEE,
    width: '200px',
    sortable: false
  },
  {
    text: 'Delivery contribution',
    value: FIELD_NAMES.DELIVERY_FEE,
    width: '180px',
    sortable: false
  },
  {
    text: 'Level 2 activated',
    value: FIELD_NAMES.LEVEL_TWO_ACTIVATED,
    width: '160px',
    sortable: true
  },
  { text: 'Dr accounts', value: FIELD_NAMES.DOCTOR_ACCOUNTS, width: '140px', sortable: true },
  { text: 'Demo accounts', value: FIELD_NAMES.DEMO_ACCOUNTS, width: '160px', sortable: true }
];

export default {
  name: 'CountriesPage',
  components: { TablePageMainHeader, DataTable },
  tableHeadersConfig: COUNTRIES_TABLE_HEADER_CONFIG,
  data() {
    return {
      isLoading: false,
      options: {},
      countries: [],
      serverItemsLength: 0
    };
  },
  watch: {
    options() {
      this.updateCountries();
    }
  },
  methods: {
    async updateCountries() {
      this.countries = await this.getCountries();
    },
    async getCountries() {
      try {
        this.isLoading = true;

        const {
          page,
          sortType = DEFAULT_SORT_BY_FIELD,
          sortDirection,
          size
        } = transformDataTableOptionsForRequest(this.options, SORTING_DIRECTIONS.ASC);

        const requestParams = {
          searchColumnName: '',
          searchQuery: '',
          sortColumnName: FIELD_NAMES_FOR_PARAMS[sortType],
          sortDirection,
          size,
          page
        };

        const {
          data: { entities, total }
        } = await getCountriesList(requestParams);

        this.serverItemsLength = total;

        return this.transformCountriesData(entities);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    },
    transformCountriesData(countries) {
      return countries.map(country => {
        const {
          logisticCenterName,
          vatRate,
          basicConsultationFee,
          deliveryContributionPercentage,
          levelTwoActivated
        } = country;

        return {
          ...country,
          logisticCenterName: logisticCenterName || '-',
          vatRate: taxFormatter(vatRate, '-'),
          basicConsultationFee: taxFormatter(basicConsultationFee, '-'),
          deliveryContributionPercentage: taxFormatter(deliveryContributionPercentage, '-'),
          levelTwoActivated: getLabelFromBoolean(levelTwoActivated),
          fee: '-'
        };
      });
    }
  }
};
</script>
